import _leaflet from "leaflet";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (factory) {
  exports = factory(_leaflet);
})(function (L) {
  var wms = {};

  if (!("keys" in Object)) {
    Object.keys = function (obj) {
      var result = [];

      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          result.push(i);
        }
      }

      return result;
    };
  }

  wms.Source = L.Layer.extend({
    options: {
      untiled: true,
      identify: true
    },
    initialize: function (url, options) {
      L.setOptions(this || _global, options);

      if ((this || _global).options.tiled) {
        (this || _global).options.untiled = false;
      }

      (this || _global)._url = url;
      (this || _global)._subLayers = {};
      (this || _global)._overlay = this.createOverlay((this || _global).options.untiled);
    },
    createOverlay: function (untiled) {
      var overlayOptions = {};

      for (var opt in (this || _global).options) {
        if (opt != "untiled" && opt != "identify") {
          overlayOptions[opt] = (this || _global).options[opt];
        }
      }

      if (untiled) {
        return wms.overlay((this || _global)._url, overlayOptions);
      } else {
        return wms.tileLayer((this || _global)._url, overlayOptions);
      }
    },
    onAdd: function () {
      this.refreshOverlay();
    },
    getEvents: function () {
      if ((this || _global).options.identify) {
        return {
          click: (this || _global).identify
        };
      } else {
        return {};
      }
    },
    setOpacity: function (opacity) {
      (this || _global).options.opacity = opacity;

      if ((this || _global)._overlay) {
        (this || _global)._overlay.setOpacity(opacity);
      }
    },
    bringToBack: function () {
      (this || _global).options.isBack = true;

      if ((this || _global)._overlay) {
        (this || _global)._overlay.bringToBack();
      }
    },
    bringToFront: function () {
      (this || _global).options.isBack = false;

      if ((this || _global)._overlay) {
        (this || _global)._overlay.bringToFront();
      }
    },
    getLayer: function (name) {
      return wms.layer(this || _global, name);
    },
    addSubLayer: function (name) {
      (this || _global)._subLayers[name] = true;
      this.refreshOverlay();
    },
    removeSubLayer: function (name) {
      delete (this || _global)._subLayers[name];
      this.refreshOverlay();
    },
    refreshOverlay: function () {
      var subLayers = Object.keys((this || _global)._subLayers).join(",");

      if (!(this || _global)._map) {
        return;
      }

      if (!subLayers) {
        (this || _global)._overlay.remove();
      } else {
        (this || _global)._overlay.setParams({
          layers: subLayers
        });

        (this || _global)._overlay.addTo((this || _global)._map);
      }
    },
    identify: function (evt) {
      var layers = this.getIdentifyLayers();

      if (!layers.length) {
        return;
      }

      this.getFeatureInfo(evt.containerPoint, evt.latlng, layers, (this || _global).showFeatureInfo);
    },
    getFeatureInfo: function (point, latlng, layers, callback) {
      var params = this.getFeatureInfoParams(point, layers),
          url = (this || _global)._url + L.Util.getParamString(params, (this || _global)._url);
      this.showWaiting();
      this.ajax(url, done);

      function done(result) {
        this.hideWaiting();
        var text = this.parseFeatureInfo(result, url);
        callback.call(this || _global, latlng, text);
      }
    },
    ajax: function (url, callback) {
      ajax.call(this || _global, url, callback);
    },
    getIdentifyLayers: function () {
      if ((this || _global).options.identifyLayers) return (this || _global).options.identifyLayers;
      return Object.keys((this || _global)._subLayers);
    },
    getFeatureInfoParams: function (point, layers) {
      var wmsParams, overlay;

      if ((this || _global).options.untiled) {
        wmsParams = (this || _global)._overlay.wmsParams;
      } else {
        overlay = this.createOverlay(true);
        overlay.updateWmsParams((this || _global)._map);
        wmsParams = overlay.wmsParams;
        wmsParams.layers = layers.join(",");
      }

      var infoParams = {
        request: "GetFeatureInfo",
        query_layers: layers.join(","),
        X: Math.round(point.x),
        Y: Math.round(point.y)
      };
      return L.extend({}, wmsParams, infoParams);
    },
    parseFeatureInfo: function (result, url) {
      if (result == "error") {
        result = "<iframe src='" + url + "' style='border:none'>";
      }

      return result;
    },
    showFeatureInfo: function (latlng, info) {
      if (!(this || _global)._map) {
        return;
      }

      (this || _global)._map.openPopup(info, latlng);
    },
    showWaiting: function () {
      if (!(this || _global)._map) return;
      (this || _global)._map._container.style.cursor = "progress";
    },
    hideWaiting: function () {
      if (!(this || _global)._map) return;
      (this || _global)._map._container.style.cursor = "default";
    }
  });

  wms.source = function (url, options) {
    return new wms.Source(url, options);
  };

  wms.Layer = L.Layer.extend({
    initialize: function (source, layerName, options) {
      L.setOptions(this || _global, options);

      if (!source.addSubLayer) {
        source = wms.getSourceForUrl(source, options);
      }

      (this || _global)._source = source;
      (this || _global)._name = layerName;
    },
    onAdd: function () {
      if (!(this || _global)._source._map) (this || _global)._source.addTo((this || _global)._map);

      (this || _global)._source.addSubLayer((this || _global)._name);
    },
    onRemove: function () {
      (this || _global)._source.removeSubLayer((this || _global)._name);
    },
    setOpacity: function (opacity) {
      (this || _global)._source.setOpacity(opacity);
    },
    bringToBack: function () {
      (this || _global)._source.bringToBack();
    },
    bringToFront: function () {
      (this || _global)._source.bringToFront();
    }
  });

  wms.layer = function (source, options) {
    return new wms.Layer(source, options);
  };

  var sources = {};

  wms.getSourceForUrl = function (url, options) {
    if (!sources[url]) {
      sources[url] = wms.source(url, options);
    }

    return sources[url];
  };

  wms.TileLayer = L.TileLayer.WMS;
  wms.tileLayer = L.tileLayer.wms;
  wms.Overlay = L.Layer.extend({
    defaultWmsParams: {
      service: "WMS",
      request: "GetMap",
      version: "1.1.1",
      layers: "",
      styles: "",
      format: "image/jpeg",
      transparent: false
    },
    options: {
      crs: null,
      uppercase: false,
      attribution: "",
      opacity: 1,
      isBack: false,
      minZoom: 0,
      maxZoom: 18
    },
    initialize: function (url, options) {
      (this || _global)._url = url;
      var params = {};

      for (var opt in options) {
        if (!(opt in (this || _global).options)) {
          params[opt] = options[opt];
          delete options[opt];
        }
      }

      L.setOptions(this || _global, options);
      (this || _global).wmsParams = L.extend({}, (this || _global).defaultWmsParams, params);
    },
    setParams: function (params) {
      L.extend((this || _global).wmsParams, params);
      this.update();
    },
    getAttribution: function () {
      return (this || _global).options.attribution;
    },
    onAdd: function () {
      this.update();
    },
    onRemove: function (map) {
      if ((this || _global)._currentOverlay) {
        map.removeLayer((this || _global)._currentOverlay);
        delete (this || _global)._currentOverlay;
      }

      if ((this || _global)._currentUrl) {
        delete (this || _global)._currentUrl;
      }
    },
    getEvents: function () {
      return {
        moveend: (this || _global).update
      };
    },
    update: function () {
      if (!(this || _global)._map) {
        return;
      }

      this.updateWmsParams();
      var url = this.getImageUrl();

      if ((this || _global)._currentUrl == url) {
        return;
      }

      (this || _global)._currentUrl = url;

      var bounds = (this || _global)._map.getBounds();

      var overlay = L.imageOverlay(url, bounds, {
        opacity: 0
      });
      overlay.addTo((this || _global)._map);
      overlay.once("load", _swap, this || _global);

      function _swap() {
        if (!(this || _global)._map) {
          return;
        }

        if (overlay._url != (this || _global)._currentUrl) {
          (this || _global)._map.removeLayer(overlay);

          return;
        } else if ((this || _global)._currentOverlay) {
          (this || _global)._map.removeLayer((this || _global)._currentOverlay);
        }

        (this || _global)._currentOverlay = overlay;
        overlay.setOpacity((this || _global).options.opacity ? (this || _global).options.opacity : 1);

        if ((this || _global).options.isBack === true) {
          overlay.bringToBack();
        }

        if ((this || _global).options.isBack === false) {
          overlay.bringToFront();
        }
      }

      if ((this || _global)._map.getZoom() < (this || _global).options.minZoom || (this || _global)._map.getZoom() > (this || _global).options.maxZoom) {
        (this || _global)._map.removeLayer(overlay);
      }
    },
    setOpacity: function (opacity) {
      (this || _global).options.opacity = opacity;

      if ((this || _global)._currentOverlay) {
        (this || _global)._currentOverlay.setOpacity(opacity);
      }
    },
    bringToBack: function () {
      (this || _global).options.isBack = true;

      if ((this || _global)._currentOverlay) {
        (this || _global)._currentOverlay.bringToBack();
      }
    },
    bringToFront: function () {
      (this || _global).options.isBack = false;

      if ((this || _global)._currentOverlay) {
        (this || _global)._currentOverlay.bringToFront();
      }
    },
    updateWmsParams: function (map) {
      if (!map) {
        map = (this || _global)._map;
      }

      var bounds = map.getBounds();
      var size = map.getSize();
      var wmsVersion = parseFloat((this || _global).wmsParams.version);
      var crs = (this || _global).options.crs || map.options.crs;
      var projectionKey = wmsVersion >= 1.3 ? "crs" : "srs";
      var nw = crs.project(bounds.getNorthWest());
      var se = crs.project(bounds.getSouthEast());
      var params = {
        width: size.x,
        height: size.y
      };
      params[projectionKey] = crs.code;
      params.bbox = (wmsVersion >= 1.3 && crs === L.CRS.EPSG4326 ? [se.y, nw.x, nw.y, se.x] : [nw.x, se.y, se.x, nw.y]).join(",");
      L.extend((this || _global).wmsParams, params);
    },
    getImageUrl: function () {
      var uppercase = (this || _global).options.uppercase || false;
      var pstr = L.Util.getParamString((this || _global).wmsParams, (this || _global)._url, uppercase);
      return (this || _global)._url + pstr;
    }
  });

  wms.overlay = function (url, options) {
    return new wms.Overlay(url, options);
  };

  function ajax(url, callback) {
    var context = this || _global,
        request = new XMLHttpRequest();
    request.onreadystatechange = change;
    request.open("GET", url);
    request.send();

    function change() {
      if (request.readyState === 4) {
        if (request.status === 200) {
          callback.call(context, request.responseText);
        } else {
          callback.call(context, "error");
        }
      }
    }
  }

  return wms;
});

export default exports;